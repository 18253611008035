import React from 'react'
import './Navbar.css'

export default function BackToSite() {
  return (
    <nav class="sticky-bar">
    <ul>
        <li><a href="/">Retour au site</a></li>
    </ul>
</nav>
  )
}
